export const environment = {
  // Endpoints
  tokenEndpoint: 'https://auth-test.oseberg.io/v2/token',
  signOutEndpoint: 'https://auth-test.oseberg.io/sign-out',

  // Local storage keys
  tokenKey: 'ose-token',
  refreshTokenKey: 'ose-refresh-token',
  usernameKey: 'ose-session-user',
  refreshExpiryKey: 'ose-refresh-expire',
  rememberedUserKey: 'ose-remembered-user',

  // Others
  refreshTokenExpireInterval: 24 * 14 * 60 * 60,
  appName: 'raven',
};
