import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthTokenService } from '../../features/login/services/auth-token.service';
import { AuthService } from '../../features/login/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,

    private authService: AuthService,
    private authTokenService: AuthTokenService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = this.setupToken(req);

    req = req.clone({ headers });

    return next.handle(req).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.handle401Error();
        }

        return throwError(() => error);
      })
    );
  }

  setupToken(req: HttpRequest<unknown>) {
    const token = this.authTokenService.getToken();

    if (!token) {
      return req.headers;
    }

    return req.headers.set('X-AUTH-TOKEN', token);
  }

  handle401Error() {
    this.authService.revokeToken();
    this.router.navigate(['/login']);
  }
}
