import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  saveNewSession(jwt: string, refresh: string, username: string) {
    const currentDate = new Date();
    const clientExpireTime =
      Math.floor(currentDate.getTime() / 1000) + environment.refreshTokenExpireInterval; // keep the token for 2 weeks
    localStorage.setItem(environment.tokenKey, jwt);
    localStorage.setItem(environment.refreshTokenKey, refresh);
    localStorage.setItem(environment.usernameKey, username);
    localStorage.setItem(environment.refreshExpiryKey, clientExpireTime.toString());
  }

  getRefreshUser(): string | null {
    return localStorage.getItem(environment.usernameKey);
  }

  getToken(): string | null {
    return localStorage.getItem(environment.tokenKey);
  }

  revoke(): void {
    localStorage.removeItem(environment.tokenKey);
    localStorage.removeItem(environment.usernameKey);
    localStorage.removeItem(environment.refreshTokenKey);
    localStorage.removeItem(environment.refreshExpiryKey);
  }

  valid(): boolean {
    const token = this.getToken();
    return !!token;
  }
}
