import { Injectable } from '@angular/core';
import { AuthService } from '../../features/login/services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthnGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate() {
    return this.checkIfUserIsLoggedIn();
  }

  checkIfUserIsLoggedIn() {
    return this.authService.isLoggedIn() ? true : this.router.createUrlTree(['/login']);
  }
}
