import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { take } from 'rxjs';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styles: [],
})
export class LoginPageComponent implements OnInit {
  loginForm!: FormGroup;
  error = '';
  shouldRememberUsername: boolean = localStorage.getItem(environment.rememberedUserKey)
    ? true
    : false;

  constructor(
    private fb: FormBuilder,
    private router: Router,

    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [localStorage.getItem(environment.rememberedUserKey), Validators.required],
      password: ['', Validators.required],
    });
  }

  shouldRememberUsernameChange(): void {
    if (!this.shouldRememberUsername) {
      localStorage.removeItem(environment.rememberedUserKey);
    }
  }

  signIn(): void {
    this.error = '';

    this.authService
      .signIn(this.loginForm.value)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.onSignIn();
        },
        error: error => {
          this.error = error.error;
        },
      });
  }

  private onSignIn(): void {
    if (this.shouldRememberUsername) {
      localStorage.setItem(environment.rememberedUserKey, this.loginForm.value.username);
    }

    this.router.navigate(['/app']);
  }

  cancel(): void {
    this.error = '';
    this.loginForm.reset();
  }
}
