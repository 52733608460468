<div class="flex flex-col items-center">
  <img src="assets/images/oseberg.png" alt="Oseberg Logo" />

  <h3 class="mb-2 mt-3 text-[34px] text-[#415c78]">oseberg</h3>
  <div class="mb-6 text-[20px] text-[#415c78]">Atla</div>

  <ng-container [formGroup]="loginForm">
    <div *ngIf="loginForm.get('username') as control" class="form-control mb-4 w-full max-w-xs">
      <input
        formControlName="username"
        type="text"
        placeholder="Username"
        class="input input-bordered w-full max-w-xs" />
      <div *ngIf="control.touched && control.invalid" class="text-sm text-[#e75724]">
        Username is required
      </div>
    </div>

    <div *ngIf="loginForm.get('password') as control" class="form-control w-full max-w-xs">
      <input
        formControlName="password"
        type="password"
        placeholder="Password"
        class="input input-bordered w-full max-w-xs" />

      <div class="flex w-full max-w-xs justify-between">
        <div
          [class.invisible]="!(control.touched && control.invalid)"
          class="text-sm text-[#e75724]">
          Password is required
        </div>

        <a class="link link-info text-sm no-underline">Reset Password</a>
      </div>
    </div>
  </ng-container>

  <div class="form-control mb-2 w-full max-w-xs">
    <label class="label w-fit cursor-pointer">
      <input
        type="checkbox"
        checked="checked"
        [(ngModel)]="shouldRememberUsername"
        (change)="shouldRememberUsernameChange()"
        class="checkbox-info checkbox checkbox-sm me-2" />
      <span class="label-text">Remember Username</span>
    </label>
  </div>

  <div
    role="alert"
    class="alert alert-error mb-4 w-full max-w-xs text-white"
    [class.invisible]="!error">
    <i class="fa-regular fa-circle-xmark shrink-0 stroke-current"></i>
    <span>{{ error }}</span>
  </div>

  <div class="flex justify-center">
    <button
      class="btn ms-3 w-36 bg-[#e75724] text-white"
      [disabled]="loginForm.invalid"
      (click)="signIn()">
      LOGIN
    </button>
    <button class="btn ms-3 w-36 bg-[#7aacbf] text-white" (click)="cancel()">CANCEL</button>
  </div>
</div>
